@media screen and (max-width: 768px) {
  div.preview {
    display: none;
  }
}

.preview-wrapper {
  .hero {
    width: 100%;
    background-color: inherit;
  }
}

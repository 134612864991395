.layout {
  min-height: calc(100vh - 100px);
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;
  padding-top: 3.25rem;
  @media screen and (min-width: 800px) {
    padding-left: 14rem;
  }
  :global(.hero) {
    background-color: #eef4f4;
  }

  &::-webkit-scrollbar {
    width: 8px !important;
  }
  &::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 5px;
  }
}

:global {
  #navbar-main {
    margin-right: 8px;
  }
}

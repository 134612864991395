.icon {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  height: 1.5rem;
  width: 1.5rem;
}

.image {
  display: block;
  position: relative;

  img {
    display: block;
    height: auto;
    width: 100%;
  }
}

.title,
.subtitle {
  word-break: break-word;
}

.title {
  color: #363636;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.125;

  strong {
    color: inherit;
    font-weight: inherit;
  }

  & + .subtitle {
    margin-top: 0.5rem;
  }
}

.subtitle {
  color: #666666;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.25;

  strong {
    color: #363636;
    font-weight: 600;
  }

  & + .title {
    margin-top: 0.5rem;
  }
}

.label {
  color: #363636;
  display: block;
  font-size: 1rem;
  font-weight: 700;

  &:not(:last-child) {
    margin-bottom: 0.5em;
  }
}

.card {
  border-color: rgba(24, 28, 33, 0.06);
  .card-header {
    background-color: transparent;
    align-items: stretch;
    box-shadow: none;
    display: flex;
  }

  .card-header-title {
    align-items: center;
    color: #363636;
    display: flex;
    flex-grow: 1;
    font-weight: 700;
    padding: 0.75rem;
  }

  .card-header-icon {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 0.75rem;
  }

  .card-header {
    border-bottom: 1px solid rgba(24, 28, 33, 0.06);
  }

  .card-content {
    background-color: transparent;
    padding: 1.5rem;
  }
}

.tile {
  align-items: stretch;
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  min-height: min-content;
}

@media screen and (min-width: 769px), print {
  .tile {
    display: flex;
  }
}

.icon i.mdi-24px {
  height: auto !important;
}

.preview {
  width: 320px;
  height: 640px;
  overflow-y: scroll;
  position: relative;
  border: 1px solid #ccc;
  border-radius: 0px;
  margin: 0 auto;
}

.tab-wrapper {
  padding: 20px;
}

.nav-tabs {
  .nav-item {
    button[aria-selected="true"] {
      border-bottom: 2px solid #33f;
      border-left-color: #ddd;
      border-right-color: #ddd;
      border-top-color: #ddd;
      font-weight: bold;
    }
    .nav-link {
      color: #333;
    }
  }
}

html,
body {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

// #root {
//   overflow: hidden;
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
// }

.react-datepicker-wrapper {
  width: 100%;
}

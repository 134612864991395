html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: 400;
}

ul {
  list-style: none;
}

button,
input,
select,
textarea {
  margin: 0;
}

html {
  box-sizing: border-box;
}

*,
::before,
::after {
  box-sizing: inherit;
}

img,
embed,
iframe,
object,
video {
  height: auto;
  max-width: 100%;
}

audio {
  max-width: 100%;
}

iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

td:not([align]),
th:not([align]) {
  text-align: left;
}

html {
  /* background-color: #f8f8f8; */
  background-color: #fff;
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  min-width: 300px;
  overflow-x: hidden;
  // overflow-y: scroll;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
}

/* Chrome */
::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* Firefox only */
body {
  scrollbar-width: none;
}

article,
aside,
figure,
footer,
header,
hgroup,
section {
  display: block;
}

body,
button,
input,
select,
textarea {
  font-family: "Nunito", sans-serif;
}

code,
pre {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto;
  font-family: monospace;
}

body {
  color: #4a4a4a;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.5;
}

a {
  color: #3273dc;
  cursor: pointer;
  text-decoration: none;

  strong {
    color: currentColor;
  }

  &:hover {
    color: #363636;
  }
}

code {
  background-color: #f5f5f5;
  color: #ff3860;
  font-size: 0.875em;
  font-weight: 400;
  padding: 0.25em 0.5em;
}

hr {
  background-color: rgba(24, 28, 33, 0.24);
  border: none;
  display: block;
  height: 1px;
  margin: 0.5rem 0;
}

img {
  height: auto;
  max-width: 100%;
}

input {
  &[type="checkbox"],
  &[type="radio"] {
    vertical-align: baseline;
  }
}

small {
  font-size: 0.875em;
}

span {
  font-style: inherit;
  font-weight: inherit;
}

strong {
  color: #363636;
  font-weight: 700;
}

pre {
  -webkit-overflow-scrolling: touch;
  background-color: #f5f5f5;
  color: #4a4a4a;
  font-size: 0.875em;
  overflow-x: auto;
  padding: 1.25rem 1.5rem;
  white-space: pre;
  word-wrap: normal;

  code {
    background-color: transparent;
    color: currentColor;
    font-size: 1em;
    padding: 0;
  }
}

table {
  td,
  th {
    vertical-align: top;
  }

  td:not([align]) {
    text-align: left;
  }

  th {
    &:not([align]) {
      text-align: left;
    }

    color: #363636;
  }
}

.dualColumns {
  display: flex;
  gap: 20px;
  padding: 20px;

  > section {
    width: 50%;
  }
  .codex-editor--narrow .ce-toolbar__actions {
    left: -60px;
  }
}

.ce-toolbar {
  left: 45px;
}
@media (min-width: 651px) {
  .codex-editor--narrow .ce-toolbox .ce-popover {
    left: -40px;
  }
  .codex-editor--narrow .ce-settings .ce-popover {
    left: 0;
    right: auto;
  }
}

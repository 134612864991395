.tooltip-content {
  width: 100%;
  max-width: 500px;
  padding-bottom: 10px;

  h2 {
    font-size: 1.3rem;
    font-weight: bold;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  h3 {
    font-size: 1rem;
    font-weight: bold;
    margin-top: 0.8rem;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 0.8rem;
  }
}

// フォームアイテム用tooltipの小さいアイコンの為の調整
.mdi.size10 {
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    font-size: 10px;
  }
}

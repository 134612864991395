$selectorButtonColor: #4c68a4;

.selectorOption {
  padding: 4px 8px;
  border: 1px solid $selectorButtonColor;
  color: $selectorButtonColor;
  margin-right: 8px;
  border-radius: 8px;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 5px;
  // -webkit-tap-highlight-color: transparent;
  background: transparent;
  &.active {
    color: #fff;
    background-color: $selectorButtonColor;
  }
  // line-height: 2.5em;
  .has-error & {
    background-color: rgba(255, 56, 96, 0.05);
    // border-color: #ff3860;
    // color: #ff3860;
    &.active {
      // background-color: #ff3860;
      color: #fff;
      background-color: $selectorButtonColor;
    }
  }
}

.selector-horizontal {
  overflow-x: auto;
  white-space: nowrap;
}

.selector-vertical {
  .selectorOption {
    display: block;
  }
}

.statTable {
  border-collapse: collapse;
  min-width: 100%;
  box-shadow: 3px 3px 5px rgba(#000, 0.1);
  font-family: sans-serif;

  td,
  th {
    border: 1px solid #ccc;
    padding: 5px;
  }
  th {
    background-color: #f4f4f4;
    color: rgb(132, 135, 136);
    font-size: 16px;
    font-weight: bold;

    &:first-child {
      border-right: 1px solid #ccc;
    }
  }
  td {
    margin-left: auto;
  }

  .sm {
    font-size: 0.8em;
    font-weight: 500;
    color: rgb(132, 135, 136),
  }
  .text-right {
    text-align: right;
  }
}
.ce-block {
  .ce-block__content {
    border-bottom: 1px solid #ccc;
    border-radius: 0 0 10px 10px;
  }

  .ce-block__content:has(h1, h2) {
    border: none;
    margin-bottom: 15px;
  }

  .ce-block__content:not(:has(h1, h2)) {
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    background: #fff;

    &:has(.ce-header, b) {
      border-bottom: none;
      border-radius: 10px 10px 0 0;
      padding: 5px 20px;
      background-color: #d5f1f3;
      color: #555;
      margin-top: 20px;
    }
  }
}

.ce-toolbar {
  left: 60px;
}

@media screen and (min-width: 1024px) {
  .statComponent {
    display: flex;
    .statBlock {
      // margin: 10px;
      width: 50%;
      padding: 30px 20px;
      overflow-x: auto;
    }
    .statBlock.statDual {
      width: inherit;
    }
  }
}
@media screen and (max-width: 1023px) {
  .statComponent {
    .statBlock {
      margin: 10px;
      overflow-x: auto;
    }
  }
}

.ptable {
  position: relative;
  overflow: scroll;
  height: calc(100vh - 92px);
  td,
  th {
    // border: 1px solid #ccc;
    white-space: nowrap;
    padding: 5px;
    border-collapse: collapse;
    // 上と左のみ影をつける（通常セル）
    box-shadow: inset 0 1px #ccc, inset 1px 0 #ccc;
  }
  th.ptable-column-end,
  td:last-of-type {
    // 右にも影をつける（最後のセル）
    box-shadow: inset 0 1px #ccc, inset 1px 0 #ccc, inset -1px 0 #ccc;
  }

  tr:last-child td,
  tr:last-child th,
  th.ptable-row-end {
    // 下にも影をつける（最後の行）
    box-shadow: inset 0 1px #ccc, inset 1px 0 #ccc, inset 0 -1px #ccc;
  }
  thead th:last-of-type,
  tr:last-child td:last-of-type,
  th.ptable-row-end.ptable-column-end {
    // 下と右にも影をつける（最後の行の最後のセル）
    box-shadow: inset 0 1px #ccc, inset 1px 0 #ccc, inset -1px 0 #ccc,
      inset 0 -1px #ccc;
  }

  thead tr,
  th {
    font-weight: bold;
    background-color: #f4f4f4;
    color: rgb(132, 135, 136);
    font-size: 16px;
    font-weight: bold;
  }
  th {
    padding: 5px 7px;
  }
  tbody tr td {
    text-align: right;
  }
  tbody tr:nth-child(2n + 1) td {
    background: #f8f8f8;
  }
  tbody tr:nth-child(2n) td {
    background: #fff;
  }
  &.ptable-stickey-row {
    thead {
      position: sticky;
      z-index: 1;
      top: 0;
    }
  }
  &.ptable-stickey-column {
    th {
      position: sticky;
      left: 0;
    }
  }
  thead tr th {
    background: #f4f4f4;
  }
  thead tr td {
    background: #f4f4f4;
  }

  tbody {
    td.ptable-level-odd {
      background: #fcfcff;
    }
    td.ptable-level-even {
      background: #f0f0ff;
    }
  }
}

table.common-table {
  width: 100%;
  th {
    .icon {
      height: 1rem;
      width: 1rem;
    }
  }
}

.isSortable:hover,
.isCurrentSort {
  border-color: #7a7a7a !important;
}

.tableIcon {
  margin-left: 0.5rem;
}

.currentPage {
  color: #2e323a !important;
  border-color: #4a4a4a !important;
  background-color: #fff;
}

.level {
  padding: 20px;
}

.range {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 30px;
  margin: 0;
  padding: 0;
  background-image: linear-gradient(#6b6f74, #6b6f74);
  background-position: left center;
  background-size: 100% 2px;
  background-repeat: no-repeat;
  background-color: transparent;

  &__error {
    background-image: linear-gradient(red, red);
  }
}
.range__input {
  box-sizing: border-box;
  background-clip: padding-box;
  padding: 0;
  margin: 0;
  font: inherit;
  color: inherit;
  background: transparent;
  border: none;
  vertical-align: top;
  outline: none;
  line-height: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: linear-gradient(#bbb, #bbb);
  background-position: left center;
  background-size: 0% 2px;
  background-repeat: no-repeat;
  height: 30px;
  position: relative;
  z-index: 1;
  width: 100%;
  &__error {
    &::-webkit-slider-thumb {
      background: rgb(255, 211, 211);
    }
    &::-moz-range-thumb {
      background: rgb(255, 211, 211);
    }
    &::-ms-thumb {
      background: rgb(255, 211, 211);
    }
  }
  &.range__input__unselected {
    &::-webkit-slider-thumb {
      border: 1px solid #ccc;
      background: #fff;
    }
    &::-moz-range-thumb {
      border: 1px solid #ccc;
      background: #fff;
    }
    &::-ms-thumb {
      border: 1px solid #ccc;
      background: #fff;
    }
  }
}
.range__input::-moz-range-track {
  position: relative;
  border: none;
  background: none;
  box-shadow: none;
  top: 0;
  margin: 0;
  padding: 0;
}
.range__input::-ms-track {
  position: relative;
  border: none;
  background-color: #6b6f74;
  height: 0;
  border-radius: 50%;
}
.range__input::-webkit-slider-thumb {
  cursor: pointer;
  position: relative;
  height: 28px;
  width: 28px;
  background-color: #4c68a4;
  border: none;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.25), 0 3px 2px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-appearance: none;
  appearance: none;
  top: 0;
  z-index: 1;
}
.range__input::-moz-range-thumb {
  cursor: pointer;
  position: relative;
  height: 28px;
  width: 28px;
  background-color: #4c68a4;
  border: none;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.25), 0 3px 2px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  margin: 0;
  padding: 0;
}
.range__input::-ms-thumb {
  cursor: pointer;
  position: relative;
  height: 28px;
  width: 28px;
  background-color: #4c68a4;
  border: none;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.25), 0 3px 2px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  margin: 0;
  padding: 0;
  top: 0;
}
.range__input::-ms-fill-lower {
  height: 2px;
  background-color: #bbb;
}
.range__input::-ms-tooltip {
  display: none;
}
.range__input:disabled {
  opacity: 1;
  pointer-events: none;
}
.range__focus-ring {
  pointer-events: none;
  top: 0;
  left: 0;
  display: none;
  box-sizing: border-box;
  background-clip: padding-box;
  padding: 0;
  margin: 0;
  font: inherit;
  color: inherit;
  background: transparent;
  border: none;
  vertical-align: top;
  outline: none;
  line-height: 1;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  height: 30px;
  position: absolute;
  z-index: 0;
  width: 100%;
}
.range--disabled {
  opacity: 0.3;
  cursor: default;
  pointer-events: none;
  pointer-events: none;
}
.range__label__top {
  display: flex;
  color: #4c68a4;
  justify-content: space-between;
}
.range__label__bottom {
  display: flex;
  color: #4c68a4;
  justify-content: center;
  min-height: 5px;
}
.range__label__selected {
  display: block;
  background-color: #4c68a433;
  color: #4c68a4;
  border: none;
  text-align: center;
  margin-top: 3px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 3px;
  &.range__input__unselected {
    background-color: #fee;
    // color: #4c68a4;
  }
}

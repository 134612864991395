.main {
  flex: 1;
  padding: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}

.title {
  color: #666;
  font-size: 16px;
  font-weight: bold;
}

.th {
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: baseline;
  gap: 4px;

  &__self {
    color: #23c4ba;
    font-size: 20px;
  }
  &__average {
    color: #bdbdbd;
  }
}

.content {
}

.list {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.name {
  color: #555;
  font-size: 16px;
  font-weight: bold;
}

.content_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.bg {
  background-color: #d5f1f3;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0 2px;
}

.graph {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 200px;
  min-height: 30px;

  &__self {
    background-color: #23c4ba;
    width: 100%;
    flex: 1;
  }
  &__average {
    background-color: #bdbdbd;
    width: 100%;
    flex: 1;
  }
}

.score {
  color: #848788;
  font-size: 14px;
  font-weight: bolder;

  &__inner {
    background-color: #fff;
    display: flex;
    align-items: baseline;
    gap: 4px;
    padding: 4px 8px;
    min-width: 220px; //pc
  }

  &__self {
    font-size: 20px;
    font-weight: bold;
    color: #23c4ba;
  }
  &__average {
  }
}

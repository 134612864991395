.editorJsWrapper {
  .ce-block__content,
  .ce-toolbar__content {
    max-width: inherit;
    // margin: 0 20px;
  }
  @media (min-width: 651px) {
    .codex-editor--narrow .codex-editor__redactor {
      margin-right: 0px;
    }
  }
  h1 {
    font-size: 2em;
    font-weight: bold;
    text-decoration: underline;
  }
  h2 {
    font-size: 1.6em;
    font-weight: bold;
  }
  h3 {
    font-size: 1.3em;
    font-weight: bold;
  }
  h4 {
    font-size: 1.1em;
    font-weight: bold;
  }
  h5 {
    font-weight: bold;
  }
}

.searchDate {
  .react-datepicker-wrapper {
    width: 220px;
    margin-left: 0 !important;
    .form-control {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
    button {
      box-shadow: none !important;
    }
  }
  .searchDateMode {
    border: 0;
    padding: 0;
    width: 140px !important;
    flex: none !important;
    :nth-child(3) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      &:focus {
        box-shadow: 0 0 0 0.125rem rgb(113 170 255 / 25%) !important;
        background: #86b6fe20;
      }
    }
  }
}

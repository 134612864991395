.field,
.field-pair {
  &:not(:last-child) {
    margin-bottom: 0.75rem;
  }
}

@media screen and (min-width: 769px), print {
  .field-pair {
    display: flex;
    min-height: 38px;
  }
  .wide-form .field-pair {
    display: block;
  }

  .field-content {
    flex: 1 1;
    align-self: center;
    min-width: 0;
  }

  .field-label {
    margin-right: 1.5rem;
    text-align: right;
    width: 80px;
    padding-top: 0.5rem;
    min-width: 80px;
  }
  .wide-form .field-label {
    width: auto;
    text-align: initial;
  }
  .field-body {
    flex: 1;
    max-width: 480px;
  }
}

.field-error-message {
  color: red;
  margin-top: 3px;
  &::before {
    display: inline;
    content: "⚠ ";
  }
}

.field-info-message {
  margin-top: 3px;
  &::before {
    display: inline;
    content: "ℹ️ ";
  }
}

.form-control {
  &.has-error {
    border-color: #ff3860;
    background: #ffeeee;
    &:focus {
      border-color: #ff3860;
      box-shadow: 0 0 0 0.125em rgba(255, 56, 96, 0.25);
    }
    &:active {
      border-color: #ff3860;
      box-shadow: 0 0 0 0.125em rgba(255, 56, 96, 0.25);
    }
  }
}

// fix bootstrap
.form-control:focus,
.form-control.focused {
  background: #86b6fe20;
  box-shadow: none;
  box-shadow: 0 0 0 0.125rem rgba(113, 170, 255, 0.25);
}

.field-decoration-wrapper {
  display: flex;
  .field-decoration-content {
    flex: 1 1;
    align-self: center;
  }
  .field-decoration {
    align-self: center;
  }
  background: #f8f8f8;
  padding: 8px 8px 8px 0;
}

.array-wrapper > div {
  margin-bottom: 0.5rem;
}

.array-wrapper-moving {
  cursor: grabbing !important;
  i,
  .field-decoration-wrapper,
  label,
  input,
  div {
    cursor: grabbing !important;
  }
}

.button-icon {
  outline: none;
  padding: 0;
  vertical-align: initial;
  &:focus {
    box-shadow: none;
  }
}

.table:not(:last-child) {
  margin-bottom: 1.5rem;
}

.table {
  background-color: #fff;
  color: #363636;

  th {
    color: #363636;

    &:not([align]) {
      text-align: left;
    }
  }
  thead {
    background-color: transparent;

    td,
    th {
      border-width: 0 0 2px;
      color: #363636;
    }
  }

  tfoot {
    background-color: transparent;

    td,
    th {
      border-width: 2px 0 0;
      color: #363636;
    }
  }

  tbody {
    background-color: transparent;

    tr:last-child {
      td,
      th {
        border-bottom-width: 0;
      }
    }
  }

  &.is-hoverable {
    tbody tr:hover {
      background-color: #fafafa;
    }

    &.is-striped tbody tr:hover {
      background-color: #fafafa;

      &:nth-child(even) {
        background-color: #f5f5f5;
      }
    }
  }

  &.is-striped tbody tr:nth-child(even) {
    background-color: #fafafa;
  }
}

table.table {
  thead th {
    // border-bottom-width: 1px;
    min-width: 60px;
  }

  td {
    color: #444;
    .image {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}

.b-table {
  @media screen and (min-width: 769px) {
    td,
    th {
      &:first-child,
      &:last-child {
        padding-left: 2em;
      }
      border: 1px solid #fafafa;
      border-width: 0 0 1px;
      padding: 0.5em 0.75em;
      vertical-align: top;
    }
  }
  @media screen and (max-width: 768px) {
    .table {
      thead {
        display: none;
      }
      tfoot th {
        border: 0;
        display: inherit;
      }
      tr {
        -webkit-box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1),
          0 0 0 1px rgba(10, 10, 10, 0.1);
        box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1);
        max-width: 100%;
        position: relative;
        display: block;
        margin-bottom: 3px !important;
      }

      tr td {
        border: 0;
        display: inherit;
        padding: 0.1rem 1rem;
      }

      tr td:last-child {
        border-bottom: 0;
      }

      tr:not(:last-child) {
        margin-bottom: 1rem;
      }

      tr:not([class*="is-"]) {
        background: inherit;
      }

      tr:not([class*="is-"]):hover {
        background-color: inherit;
      }

      tr.detail {
        margin-top: -1rem;
      }

      tr:not(.detail) td {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: auto;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        text-align: right;
        border-bottom: 1px solid #f5f5f5;
      }

      tr:not(.detail) td:before {
        content: attr(data-label);
        font-weight: 600;
        padding-right: 0.5em;
        text-align: left;
      }
    }
  }
}

.table.is-hoverable.is-striped tbody tr:hover:nth-child(even) {
  background-color: #fafafa;
}

.b-table {
  .table-main {
    overflow-x: scroll;
  }
  .table {
    border: 0;
    border-radius: 0;
  }

  .table-wrapper {
    margin-bottom: 0;

    + .level {
      padding: 1.125rem 1.5rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      margin: 0;
      border-top: rgba(24, 28, 33, 0.06);
      background: #f5f5f5;
    }
  }
}

@media screen and (max-width: 768px) {
  .b-table {
    .table {
      tr {
        box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1);
        margin-bottom: 3px !important;
      }
    }
  }
}

.page-item.active .page-link {
  z-index: 0 !important;
}
